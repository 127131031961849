import { useState } from "react";
import './App.css';


function App() {
  const [numPages, setNumPages] = useState();
  const [images, setImages] = useState([{
    url: 'https://lamineartwork.com/0001.jpg',
  },
  
  {
    url: 'https://lamineartwork.com/0002.jpg',
  },
  
  {
    url: 'https://lamineartwork.com/0003.jpg',
  },
  
  {
    url: 'https://lamineartwork.com/0004.jpg',
  },
  
  {
    url: 'https://lamineartwork.com/0005.jpg',
  },
  
  {
    url: 'https://lamineartwork.com/0006.jpg',
  },
  
  {
    url: 'https://lamineartwork.com/0007.jpg',
  },
  
  {
    url: 'https://lamineartwork.com/0008.jpg',
  }]);

  return (
    <div className="App">
      {images.map((image, index) => (
        <img src={image.url} alt="" key={index} 
        className="responsive-img"/>
      ))}
    </div>
  );
}

export default App;
